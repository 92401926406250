.partner-instructions-modal {
  max-width: 800px;

  ol {
    padding-left: 20px;
    li {
      margin-bottom: 8px;
    }
  }
}
