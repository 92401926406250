.confirmation-modal-footer {
  display: flex;
  justify-content: right;
}

.location-modal-text-input {
  height: 26px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 4px;
}

.location-modal-error-text {
  margin-bottom: 5px;
  color: red;
  font-style: italic;
}
