.performance-pane {
  flex-grow: 1;
  min-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;

  .performance-tab {
    width: 100%;
    min-height: 100%;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: column;

    .performance-card {
      position: relative;
      flex-grow: 1;
      min-height: 500px;
      padding: 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      min-width: fit-content;

      .performance-card-content {
        width: 100%;
        min-width: fit-content;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 0.75rem;

        .graph-container {
          display: flex;
          flex: 1;
          flex-direction: row;

          .line-graph-content {
            display: flex;
            flex: 3;
          }

          .bar-graph-content {
            display: flex;
            flex: 1;
            flex-direction: column;

            .title {
              text-align: center;
            }

            .bar-graph {
              display: flex;
              flex: 1;
            }

            .uplift-container {
              display: flex;
              flex-direction: row;

              .uplift {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                margin-top: 1rem;

                &.uplift-positive {
                  color: #0ec647;
                }

                &.uplift-negative {
                  color: #c72913;
                }

                .triangle {
                  margin-right: 10px;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                }

                .triangle-up {
                  border-bottom: 12px solid #0ec647;
                  margin-bottom: 1px;
                }

                .triangle-down {
                  border-top: 12px solid #c72913;
                  margin-top: 1px;
                }
              }
            }
          }

          .vertical-divider {
            width: 1px;
            background-color: black;
          }
        }

        .legend-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 1rem;
          gap: 1rem;

          .legend-item {
            display: flex;
            align-items: center;

            .legend-line {
              width: 30px;
              height: 4px;
              margin-right: 8px;
            }

            .legend-dashed-line {
              border-top: 2px dashed #64f884;
              width: 30px;
              height: 5px;
              margin-right: 8px;
            }

            .legend-text {
              font-size: 16px;
              color: var(--font-black);
            }
          }
        }
      }
    }
  }
}

.date-control-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .title {
    color: var(--font-black);
  }

  .button {
    font-size: 2rem;
    color: var(--font-black);
    &:disabled {
      opacity: 0.3;
    }
  }
}
